import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "./utils/cn.ts";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium inline-flex items-center justify-center cursor-pointer transition-all rounded-lg",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground hover:bg-primary/90 border-primary-foreground/20 hover:brightness-110 border-b-[3px] active:border-b-[1px] active:brightness-90 active:translate-y-[1px] aria-expanded:border-b-[1px] aria-expanded:brightness-90 aria-expanded:translate-y-[1px] shadow-inner",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90 hover:brightness-110 text-white border-b-[3px] active:border-b-[1px] active:brightness-90 active:translate-y-[1px] aria-expanded:border-b-[1px] aria-expanded:brightness-90 aria-expanded:translate-y-[1px] shadow-inner",
        warning:
          "bg-warning text-warning-foreground hover:bg-warning/80 hover:brightness-110 text-white border-b-[3px] active:border-b-[1px] active:brightness-90 active:translate-y-[1px] aria-expanded:border-b-[1px] aria-expanded:brightness-90 aria-expanded:translate-y-[1px] shadow-inner",
        outline:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80 border-secondary-foreground/20 hover:brightness-100 outline-secondary-foreground/20 ",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80 border-secondary-foreground/20 hover:brightness-100 outline-secondary-foreground/20 border-b-[3px] active:border-b-[1px] active:brightness-90 active:translate-y-[1px] aria-expanded:border-b-[1px] aria-expanded:brightness-90 aria-expanded:translate-y-[1px] shadow-inner",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <button
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
